$(function() {
    // Global variable
    window.token = $('meta[name="csrf-token"]').attr('content');

    const btn = $('button[type="submit"]');
    const spinner = $('span[role="status"]');

    // Show preloader
    $(document).on('ajaxSend', function() {
        btn.prop('disabled', true);
        spinner.removeClass('visually-hidden');
    }).on('ajaxComplete', function() {
        spinner.addClass('visually-hidden');
    }).on('ajaxError', function() {
        btn.prop('disabled', false);
    });
});

// Extend jQuery
$.fn.extend({
    dropdownList: async function(url, param, valueType, el) {
        try {
            const data = await $.getJSON(url + param);
            $.each(JSON.parse(data).data, function(key, value) {
                el.append(
                    $('<option>', {
                        value: valueType === 'text' ? value.name : value.id,
                        text: value.name
                    })
                );
            });
        } catch (error) {
            console.error('Error fetching dropdown data:', error);
        }
    },
    cascadingDropdownList: async function(parentUrl, childUrl, defaultOptionText, paramType, valueType, parentDropdown, childDropdown) {
        try {
            const parentData = await $.getJSON(parentUrl);
            $.each(JSON.parse(parentData).data, function(key, value) {
                parentDropdown.append(
                    $('<option>', {
                        value: valueType === 'text' ? value.name : value.id,
                        text: value.name,
                        'data-id': value.id // Append value.id as a data attribute
                    })
                );
            });

            // Handle parent dropdown change event
            parentDropdown.on('change', async function() {
                const selectedText = $(this).find('option:selected').text();
                const selectedValue = $(this).find('option:selected').data('id'); // Get value.id from the data attribute

                childDropdown.empty(); // Clear existing options in child dropdown
                childDropdown.append($('<option>', { value: '', text: defaultOptionText, selected: true, disabled: true, hidden: true })); // Add a default option with dynamic text

                const param = paramType === 'text' ? selectedText : selectedValue;

                // Make a new request for child dropdown options based on the selected parameter
                const childData = await $.getJSON(childUrl + '?param=' + param);
                $.each(JSON.parse(childData).data, function(key, value) {
                    childDropdown.append(
                        $('<option>', {
                            value: valueType === 'text' ? value.name : value.id,
                            text: value.name
                        })
                    );
                });
            });
        } catch (error) {
            console.error('Error fetching dropdown data:', error);
        }
    },
    inputText2Number: function(input) {
        // Change input type
        const mediaQuery = window.matchMedia('(min-width: 992px)');

        const handleWindowChange = (mq) => {
            (mq.matches)
                ? input.attr('type', 'text').removeAttr('step pattern')
                : input.attr({type: 'number', step: 'any', pattern: '[0-9]*[.,]?[0-9]+' });
        };

        // Call the handler initially
        handleWindowChange(mediaQuery);

        // Attach the handler to the media query
        return mediaQuery.addEventListener('change', (e) => handleWindowChange(e.target));
    },
    dateFormatter: function(options) {
        // Default settings
        const settings = $.extend({
            dateInput: 'input[name="date_input"]',
            dateOutput: 'input[name="date"]' || 'input[name="birthdate"]'
        }, options);

        // Attach input event handler to the matched elements
        this.on('input', function() {
            const dateInput = $(settings.dateInput, this);
            const dateOutput = $(settings.dateOutput, this);
            const dateValue = dateInput.val();

            // Use regex to extract day, month, and year from the date input
            const match = dateValue.match(/^(\d{1,2})\/(\d{1,2})\/(\d{4})$/);

            if (match) {
                const day = match[1];
                const month = match[2];
                const year = match[3];

                // Create a date object to validate the date
                const formattedDate = new Date(year + '-' + month + '-' + day);

                // Check if the formatted date is valid
                (!isNaN(formattedDate.getTime()))
                   ? dateOutput.val(year + '-' + month + '-' + day)
                   : dateOutput.val('');

            } else {
                dateOutput.val('');
            }
        });

        return this; // Enable chaining
    },
    dateToLocalString: function(param) {
        const date = new Date(param); // parse the date string
        const day = date.toLocaleString('default', { day: 'numeric' }); // get day in numeric form
        const month = date.toLocaleString('default', { month: 'long' }); // get month in long form
        const year = date.toLocaleString('default', { year: 'numeric' }); // get year in numeric form
        // format the date as per requirement
        return `${day} ${month} ${year}`;
    },
    disableContextMenu: function() {
        // Disable context menu (right click menu)
        $('body').on('contextmenu', function(e) {
            e.preventDefault();
        });
    },
    disableHighLighting: function() {
        // Disable highlighting
        $('body').on('selectstart', function(e) {
            e.preventDefault();
        });
    },
    notif: function(key, value) {
        (typeof(Storage) !== 'undefined')
            ? localStorage.setItem(key, value)
            : console.log('Sorry! No Web Storage support..');
    },
    error: function(el, msg) {
        return el.find('p').html(msg);
    }
});
